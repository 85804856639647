/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  activeExam: null,
  typeList: [],
  userList: [],
  doneList: [],
  questionList: [],
  activeQuestion: null,
  activeQuestionIndex: 0,
  categoryList: [],
  categoryMain: [],
  categorySub: [],
  doneType: false,
  activeGrade: [],
  activeType: [],
  activeYear: '연도별',
  activeTab: {
    name: '과목별',
    grade_id: '',
    subject_type_id: 0
},
}
