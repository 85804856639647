/*=========================================================================================
  File Name: moduleCalendarMutations.js
  Description: Calendar Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js"

export default {
  SET_PAYMENT_PRODUCT(state, payload) {
    state.paymentProduct = payload
    // console.log(state.paymentProduct)
  },
  SET_PAYMENT_INFO(state, payload) {
    state.paymentInfo = payload
  },
  SET_CALCULATE_LIST(state, payload) {
    state.calculateList = payload
  },
  SET_PREMIUM_LIST(state, payload) {
    state.premiumList = payload
  },
  SET_ACTIVE_PRODUCT(state, payload) {
    state.activeProduct = payload
  },
  SET_USER_DATA(state, payload) {
    state.userData = payload
    console.log(state.userData)
  },
  SET_USER_ORDER_ID(state, payload) {
    state.orderId = payload
  },

  // 2023-03-28 edit, add 
  setName(state, name) {
    return (state.name = name);
  },
  setPeriod(state, period) {
    return (state.period = period);
  },
  setTotalSolved(state, totalSolved) {
    return (state.totalSolved = totalSolved);
  },
  setGrade(state, grade) {
    return (state.grade = grade);
  },
  setTimePerDay(state, day) {
    return (state.timePerDay = day);
  },
  setTotalTime(state, time) {
    return (state.totalTime = time);
  },
  setRecommendedSchoolList(state, list) {
    return (state.recommendedSchoolList = list);
  },
  setRecommendedMajorList(state, list) {
    return (state.recommendedMajorList = list);
  },
  setUpgradeRecommendedSchoolList(state, list) {
    return (state.upgradeRecommendedSchoolList = list);
  },
  setUpgradeRecommendedMajorList(state, list) {
    return (state.upgradeRecommendedMajorList = list);
  },

  HANDLE_PAGE_INDEX(state, nextIndex) {
    return state.pageIndex = nextIndex;
  },


  SET_BEFORE_UNIV_LIST(state, payload) {
    state.beforeUnivList = [];
    state.beforeUnivList = payload;
  },
  SET_AFTER_UNIV_LIST(state, payload) {
    state.afterUnivList = [];
    state.afterUnivList = payload;
  },
  SET_PAYMENT_AI_SERVICE_INFO(state, payload) {
    state.paymentAiServiceInfo = payload;
  }
}
