/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"
import axios_filledyou from "@/axios_custom.js"

export default {
  tossPayAuth({
  commit}, payload) {
    return new Promise((resolve, reject) => {
      axios.post("/api/v2/common/toss_auth", payload).then((response) => {
        console.log(response)
        if (response.data.result == 'success'){
          commit('SET_PAYMENT_INFO', response.data.data)
          resolve(response.data.data)
        }
        reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getCalculationList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("api/v2/common/account?start_date=" + payload.start_date +"&end_date=" + payload.end_date).then((response) => {
        if (response.data.result == 'success'){
          commit('SET_CALCULATE_LIST', response.data.data_list)
          resolve(response.data.data_list)
        }
        reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  getPaymentProduct({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("api/v2/common/user/payment_product").then((response) => {
        if (response.data.result == 'success'){
          commit('SET_PREMIUM_LIST', response.data.data_list)
          resolve(response.data.data_list)
        }
        reject(resolve)
      }).catch((error) => {
        reject(error)
      })
    })
  },
  checkPremium({
    commit}, payload) {
      console.log(axios_filledyou.defaults.headers.common.authorization);
      return new Promise((resolve, reject) => {
        axios_filledyou.get("/api/v2/common/user/unlimited?user_id=" + payload).then((response) => {
          console.log(response)
          if (response.data.result == 'success'){
            resolve(response.data.data)
          }
          reject(resolve)
        }).catch((error) => {
          reject(error)
        })
      })
    },


  // user point
  giveUserPoint({commit}, payload) {
    return new Promise((resolve, reject) => {
      const baseURL = axios.defaults.baseURL == "http://211.236.62.51:7878" ? "http://211.236.62.51:7771" : "https://filledyou.com:7770";
      axios.put(`${baseURL}/api/v2/common/point_give`, payload).then((response) => {
        console.log(payload);
        console.log(response);
        if (response.data.result == 'success') {
          resolve(response)
        }
        reject(response)
      }).catch((error) => {reject(error)})
    })
  },
}
