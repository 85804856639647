/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  getTypeList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/class_exam")
        .then((response) => {
          // console.log(response)
          if (response.data.result == 'success') {
            commit("SET_TYPE_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getExamDetail({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/all_subject_exam?id=" + payload.id + '&type=' + payload.type)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_EXAM", response.data.data)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getUserTypeList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/class_exam_user?user_id=" + payload)
        .then((response) => {
          // console.log(response)
          if (response.data.result == 'success') {
            commit("SET_USER_TYPE_LIST", response.data.data_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getQuestionList({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question_subject/class?id=" + payload.id + "&type=" + payload.type)
        .then((response) => {
          console.log(response)
          if (response.data.result == 'success') {
            commit("SET_ACTIVE_EXAM", response.data.data.subject_info)
            commit("SET_QUESTION_LIST", response.data.data.question_list)
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  getQuestionItem({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/exam/question/" + payload)
        .then((response) => {
          if (response.data.result == 'success') {
            console.log(response.data.data)
            commit('SET_ACTIVE_QUESTION', response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCategoryList({
    commit
  }) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/subject/category/-1")
        .then((response) => {
          if (response.data.result == 'success') {
            commit('SET_CATEGORY_LIST', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCategoryMain({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/subject/main-category?id=" + payload.id + '&grade_id=' + payload.grade_id)
        .then((response) => {
          if (response.data.result == 'success') {
            // console.log(response.data.data)
            commit('SET_CATEGORY_MAIN', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getCategorySub({
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      axios.get("/api/v2/subject/sub-category?id=" + payload.id + '&grade_id=' + payload.grade_id)
        .then((response) => {
          if (response.data.result == 'success') {
            // console.log(response.data.data)
            commit('SET_CATEGORY_SUB', response.data.data_list)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  saveEditInfo(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/question_class", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            resolve(response)
          }
          reject(response)
        }).catch((error) => {
          reject(error)
        })
    })
  },
  changeState(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/class_exam", payload)
        .then((response) => {
          if (response.data.result == 'success') {
            // console.log(response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  examDone(context, payload) {
    return new Promise((resolve, reject) => {
      axios.put("/api/v2/exam/class_exam_done?type=" + payload.type + '&id=' + payload.id)
        .then((response) => {
          if (response.data.result == 'success') {
            // console.log(response.data.data)
            resolve(response)
          }
          reject(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
}
