/*=========================================================================================
  File Name: moduleCalendarState.js
  Description: Calendar Module State
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  paymentProduct: {},
  paymentInfo: {},
  calculateList: [],
  premiumList: [],
  activeProduct: null,
  orderId: null,
  userData: {
    user_id: null,
    user_name: null
  },

  // 2023-03-28 edit, add 
  name: "필드유",
  period: "100",
  cost: "100000",
  totalSolved: "400",
  grade: "5",
  timePerDay: "241",
  totalTime: "2220",
  recommendedSchoolList: ["안양대학교", "수원대학교", "강남대학교"],
  recommendedMajorList: [
    "컴퓨터공학컴퓨터공학",
    "소프트웨어공학",
    "산업디자인",
  ],
  upgradeRecommendedSchoolList: ["서울대학교", "고려대학교", "연세대학교"],
  upgradeRecommendedMajorList: [
    "소프트웨어공학",
    "엄청긴이름을가진학과는어디지",
    "산업디자인",
  ],
  pageIndex: 0,



  beforeUnivList: [],
  afterUnivList: [],


  paymentAiServiceInfo: false,
}
